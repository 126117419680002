import React from 'react';
import styles from './styles.module.css';
import { Layout } from 'src/components/Layout';

import { SparklyCheckMark } from 'src/components/PuzzleComplete/components/SVGs/SparklyCheckMark';
import { UserManager } from 'src/helpers/UserManager';
import { navigate } from 'gatsby';
import { Spinner } from 'src/components/Spinner';

interface Props {}

interface State {
	loading: boolean;
	email: string;
}

export default class Checkout extends React.Component<Props, State> {
	state: State = {
		loading: true,
		email: '',
	};

	async componentDidMount() {
		const user = await UserManager.getAuthenticatedUser();
		if (!user || user.guest) {
			navigate('/');
			return;
		}

		this.setState({
			email: user.email ?? '',
			loading: false,
		});
	}

	render() {
		const { loading, email } = this.state;
		return (
			<Layout>
				<div className={styles.container}>
					{!loading ? (
						<div className={styles.content}>
							<div className={styles.heading}>Thanks for your order.</div>
							<SparklyCheckMark />
							<div className={styles.text}>
								Check your <strong>{`${email}`}</strong> inbox for a Profile download link. If you
								can’t see it, please check your spam folder.
							</div>
						</div>
					) : (
						<Spinner />
					)}
				</div>
			</Layout>
		);
	}
}
