import React from 'react';

export const SparklyCheckMark = () => {
	return (
		<svg
			width="199px"
			height="103px"
			viewBox="0 0 199 103"
			version="1.1"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			xmlns="http://www.w3.org/2000/svg"
		>
			<desc>Sparkly Check Mark</desc>
			<defs>
				<linearGradient x1="0.5" y1="0" x2="0.5" y2="1" id="gradient_1">
					<stop offset="0" stopColor="#DFFFF7" stopOpacity="0" />
					<stop offset="1" stopColor="#BCFFEC" />
				</linearGradient>
			</defs>
			<g id="sparkles" transform="translate(0.310791 0.310791)">
				<path
					d="M21.572 21.2087C34.4762 19.529 33.9312 15.0877 35.7808 0C37.5347 15.0682 37.1011 19.5525 49.9877 21.2087C37.2007 23.4606 37.5445 27.8317 35.7808 42.4174C34.441 27.9157 34.2574 23.2692 21.572 21.2087ZM7.66787 0.388672C6.66984 8.53114 6.96475 10.9276 0 11.8338C6.84562 12.9451 6.94522 15.4529 7.66787 23.279C8.62098 15.4061 8.43544 13.0487 15.3357 11.8338C8.38075 10.9413 8.61512 8.52138 7.66787 0.388672ZM9.26953 31.2653C14.1542 30.6286 13.9472 28.9489 14.6464 23.24C15.3105 28.9411 15.1464 30.6383 20.0233 31.2653C15.1835 32.1168 15.3144 33.7711 14.6464 39.2906C14.1386 33.8024 14.0703 32.0446 9.26953 31.2653Z"
					transform="translate(147.6851 49.91821)"
					id="Fill-1"
					fill="#C9FFEF"
					fillRule="evenodd"
					stroke="none"
				/>
				<path
					d="M21.572 21.2087C34.4762 19.529 33.9312 15.0877 35.7808 0C37.5347 15.0682 37.1011 19.5525 49.9877 21.2087C37.2007 23.4606 37.5445 27.8317 35.7808 42.4174C34.441 27.9157 34.2574 23.2692 21.572 21.2087ZM7.66787 0.388672C6.66984 8.53115 6.96475 10.9276 0 11.8338C6.84561 12.9452 6.94522 15.4529 7.66787 23.279C8.62098 15.4061 8.43544 13.0487 15.3357 11.8338C8.38075 10.9413 8.61512 8.52138 7.66787 0.388672ZM9.26953 31.2653C14.1542 30.6286 13.9472 28.9489 14.6464 23.24C15.3105 28.9411 15.1464 30.6383 20.0233 31.2653C15.1835 32.1168 15.3144 33.7711 14.6464 39.2906C14.1386 33.8024 14.0703 32.0446 9.26953 31.2653Z"
					transform="translate(147.6851 49.91821)"
					id="Stroke-2"
					fill="none"
					fillRule="evenodd"
					stroke="none"
				/>
				<path
					d="M38.117 18.879C21.787 17.3868 20.568 16.1876 19.0645 0C17.5567 16.1875 16.3379 17.3865 0 18.879C16.338 20.3634 17.5565 21.5802 19.0645 37.76C20.5684 21.5805 21.7872 20.3635 38.117 18.879Z"
					id="Fill-1"
					fill="#C9FFEF"
					stroke="none"
				/>
				<path
					d="M7.2615 0C6.68535 6.164 6.2205 6.623 0 7.1935C6.2205 7.75405 6.6855 8.2267 7.2615 14.3945C7.82595 8.2245 8.29275 7.754 14.5215 7.1935C8.293 6.62515 7.826 6.1642 7.2615 0Z"
					transform="translate(5.066406 35.26746)"
					id="Fill-2"
					fill="#C9FFEF"
					stroke="none"
				/>
				<path
					d="M4.7207 9.3457C5.0957 5.33985 5.39845 5.0449 9.44335 4.66795C5.4004 4.30076 5.0957 4.00585 4.7207 0C4.34961 4.00585 4.0449 4.3008 0 4.66795C4.0449 5.04685 4.3496 5.3418 4.7207 9.3457Z"
					transform="translate(30.1792 23.51575)"
					id="Fill-3"
					fill="#C9FFEF"
					stroke="none"
				/>
				<g id="icon/tick" transform="translate(45.67944 13.62695)">
					<path
						d="M42.5 85C65.9721 85 85 65.9721 85 42.5C85 19.0279 65.9721 0 42.5 0C19.0279 0 0 19.0279 0 42.5C0 65.9721 19.0279 85 42.5 85Z"
						transform="translate(0 3)"
						id="Oval"
						fill="url(#gradient_1)"
						stroke="none"
					/>
					<path
						d="M28 14C43.464 14 56 10.866 56 7C56 3.13401 43.464 0 28 0C12.536 0 0 3.13401 0 7C0 10.866 12.536 14 28 14Z"
						transform="translate(15 53)"
						id="Oval"
						fill="#97F3D8"
						fillOpacity="0.464704245"
						stroke="none"
					/>
					<g id="Group-6" transform="translate(18 6.714629E-13)">
						<path
							d="M5.9109 3.43503L12.9034 7.42L6.99255 3.98524L0 0L5.9109 3.43503Z"
							transform="translate(43.04749 -1.37931E-05)"
							id="Fill-1"
							fill="#4FEABE"
							stroke="none"
						/>
						<path
							d="M24.6043 0L0 42.6059L5.9109 46.0408L30.5152 3.43503L24.6043 0Z"
							transform="translate(18.44316 0)"
							id="Fill-2"
							fill="#4FEABE"
							stroke="none"
						/>
						<path
							d="M5.9109 3.4349L0 0L11.496 6.56138L17.4069 9.99628L5.9109 3.4349Z"
							transform="translate(6.947241 36.0445)"
							id="Fill-3"
							fill="#4FEABE"
							stroke="none"
						/>
						<path
							d="M0 12.0327L5.9109 15.4679L12.8581 3.4349L6.94717 0L0 12.0327Z"
							transform="translate(0 36.04444)"
							id="Fill-4"
							fill="#4FEABE"
							stroke="none"
						/>
						<path
							d="M18.4432 42.6058L6.94717 36.0444L0 48.0772L11.4959 54.6388L18.4884 58.6339L50.04 3.98497L43.0475 0L18.4432 42.6058Z"
							transform="translate(5.910952 3.434979)"
							id="Fill-5"
							fill="#35D4A6"
							stroke="none"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};
